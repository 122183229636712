import { action, observable, makeObservable } from 'mobx'
import { TransactionTreeProp } from '@/constants/Transaction'

class TxnTreeStore {
  constructor() {
    makeObservable(this)
  }
  @observable cache: Pick<TransactionTreeProp, 'selected' | 'source'> = {
    selected: [],
    source: undefined,
  }

  @action setTxnCacheSelected = (selected: TransactionTreeProp['selected']) => {
    this.cache.selected = selected
  }
  @action setTxnCacheSource = (source: TransactionTreeProp['source']) => {
    this.cache.source = source
  }

  @action clearTxnCache = () => {
    this.cache = {
      selected: [],
      source: undefined,
    }
  }
}

export default new TxnTreeStore()
