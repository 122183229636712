import React from 'react'
import { defaultLocale, LocaleType } from '@/i18n/config'
export interface ContextProps {
  readonly books: { [key: string]: any }
  readonly locale: LocaleType
}
export const LanguageContext = React.createContext<ContextProps>({
  books: {},
  locale: defaultLocale,
})

export const LanguageProvider: React.FC<ContextProps> = ({ books, locale, children }) => {
  return <LanguageContext.Provider value={{ books, locale }}>{children}</LanguageContext.Provider>
}
