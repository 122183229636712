const { vsprintf, sprintf } = require('sprintf-js')

export function gettext(msgid: string, argArrs: (string | number)[] | { [key: string]: any } = [], book?: KV<string>) {
  let result = msgid
  try {
    book = (typeof window === 'object' && window.langsBook) || book
    result = (Array.isArray(argArrs) ? vsprintf : sprintf)((book || {})[msgid] || msgid, argArrs)
  } catch (e) {
    // todo
  }
  return result
}

export const setLangsBook = (book: { [messageId: string]: string }) => {
  if (typeof window !== 'undefined') {
    window.langsBook = book
  }
}
